import React from 'react'
import { Card, Row, Col, Divider } from 'antd';
import moment from 'moment'
import CheckBox from "../../../components/check-box/CheckBox";
import EditService from '../../../components/edit-service/EditService';
import { EditOutlined} from '@ant-design/icons';
import TicketSubmit from "../../../components/ticketSubmit/TicketSubmit"
import { usaFormatNumber } from '../../../utils/helper';
const WashoutCertificate=(props)=>{
const {serviceCheck,lastKnown,from,washDate,
    ticketWashClose,ticketWashSubmit,onChangeTicket,
    load,ticketWashEdit,certificateLogo,serviceData,clientName,
    invoice,ticketShow,edit,error,ticket,nextTicket}= props
    return(
      
<Card>
<div className="page-header d-flex" style={{textAlign: "center",alignItems:'center',justifyContent:'space-between',paddingRight:20}}>
<div className="client-header d-flex" style={{padding:'10px 23px 23px 23px',alignItems:'center'}}>
<div className="client-logo-certificate" >
							<img style={{width:'100%',backgroundSize:'cover'}} src={certificateLogo||''} alt="invoice_logo"/>
							</div>
						<span style={{fontSize:36, fontWeight:500}}>
							{invoice?.client_details.company_name}</span>
					</div>
					<div style={{display:'flex',alignItems:"flex-start",justifyContent:'space-between'}}>
			  <span className="certificate-bold" style={{marginRight:"7px"}}>Certificate No.:{ticketShow?nextTicket:""}</span>
			  {ticketShow?<div style={{cursor:"pointer"}} className="wash_certificate_ticket_edit" onClick={ticketWashEdit}>{edit?<EditOutlined />:""}</div>
			  :
			  <TicketSubmit load={load} type="text" name="ticket" value={nextTicket==="NA"?"":nextTicket} onChange={(e)=>onChangeTicket(e)} ticketWashSubmit={()=>ticketWashSubmit()} ticketWashClose={ticketWashClose} error={error} btnshow={nextTicket===ticket || nextTicket ===""}/>
											}
            
			 </div>
  </div>

  <div className="page-footer">
  <div className="text-center hide-footer" >
  <span style={{fontWeight:700}}>  {invoice?.client_details.billing_address+', '+invoice?.client_details.billing_city+', '+invoice?.client_details.billing_state+', '+invoice?.client_details.billing_zipcode} {usaFormatNumber(invoice?.client_details.billing_phone)}</span>
						<p>Electronically produced on <span style={{color:'rgb(239 198 73)'}}>WashTRAX<sup>TM</sup></span> and no signature required. All rights reserved. © 2021   </p>
					</div> 
  </div>

  <table>

    <thead>
      <tr>
        <td>
          {/* <!--place holder for the fixed-position header--> */}
          <div className="page-header-space"></div>
        </td>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>
          {/* <!--*** CONTENT GOES HERE ***--> */}
          <div className="page">
		  <div  style={{textAlign: "center"}}>
					<div className="certificate-header">
						<h2>
						Certificate of Washout
						</h2>
					</div>
        </div>
        <div className="text-center mt-2  font-size-17">
		  <div style={{display:'flex',justifyContent:'space-between'}}>	

		     {/* <div> 
				 <span className="certificate-bold">Date :</span>{moment(Date.now()).format('MM/DD/YYYY')} 
				 </div> */}
		     {/* <div><span className="certificate-bold">Wash Ticket : </span>{invoice?.ticket_ref_id}</div> */}

		</div>
		</div>
		<Divider style={{margin:'14px 0px'}}/>
	  				<Row gutter={24} className="mt-2">
					  <Col span={15}><div className="mt-1 font-size-16"><span className="certificate-bold">Customer Name:</span> {invoice?.customer_details.name}</div></Col>
					  <Col span={9}><div className="mt-1 font-size-16"><span className="certificate-bold">Equipment No.:</span> {invoice?.asset_details.equipment_no}</div></Col>
							<Col span={15}><div className="mt-1 font-size-16"><span className="certificate-bold">Date of Wash:</span> {moment(washDate).format('MM/DD/YYYY, hh:mm A')}</div></Col>
						<Col span={9}><div className="mt-1 font-size-16"><span className="certificate-bold">Equipment Type:</span> {invoice?.asset_details?.equipment_type_details?.equipment_category_details?.param_description} - {invoice?.asset_details?.equipment_type_details?.param_description}</div></Col>
						<Col span={15} className="mt-1 font-size-16"><div><span className="certificate-bold">From:</span> {from}</div></Col>
						<Col span={9} className="mt-1 font-size-16"><div><span className="certificate-bold">Last Contained:</span> {lastKnown}</div></Col>
					</Row>
					<Divider style={{margin:'14px 0px'}}/>

					

					<div className="mt-5 content-block">
						<h5 className="text-center mt-3" style={{fontWeight:'bold',fontSize:'1.4rem'}}>Statement of Certification</h5>
						<div className="mt-3 font-size-16 text-center certificate-bold" style={{fontWeight:500,lineHeight: 2}}>
						This certificate confirms that the item(s) listed above have been cleaned and inspected by <span className="certificate-bold" style={{color:'#fd8f46'}} >{clientName}</span>. Prior to removal, the item(s) must be inspected by a customer representative.
By receiving this certificate electronically, the customer agrees that the unit is clean and
accepts custody as is.	
						</div>
						
					</div>
					{/* {invoice?.client_details?.billing_phone} */}
					<Row className="content-block" gutter={24} style={{marginTop:'1rem'}}>
						<Col span={12} className="font-size-15">
						
						</Col>
						<Col span={12} className="font-size-15">
						<div className="text-right">
					
						</div>
						</Col>
					</Row>
					<Row>
						<Col span={15}>
					<div className="mt-3">
						<span className="certificate-bold font-size-16">Services Performed</span>
						{/* <table style={{marginLeft:'3.5rem',marginTop:10,marginBottom:'2vh'}}>
						<tr className="d-flex" style={{flexDirection:'column',marginRight:10}}>
						{invoiceData&& invoiceData.map((item,i)=><td key={i.toString()} className="font-size-17 mt-1 content-block">{item.service}</td>)}

						</tr>
						</table> */}
						<Row gutter={24} style={{marginTop:10}}>
						{/* {invoiceData&& invoiceData.map((item,i)=><Col span={6} className="font-size-17 mt-1 content-block">
						{item.service}
						</Col>)} */}
						<div className="wash_update_service">
						{serviceData && serviceData.map((item,index)=>{
							return <EditService  key={index} name={item.param_description} show={item.show}/>
						})}
						</div>
						</Row>
					</div>
					</Col>
					<Col span={9}>
					<div className="mt-3 font-size-16 d-flex" style={{flexDirection:'column'}}>
						<span className='certificate-bold '>Washout Facility Representative</span> 
						<span style={{marginTop:5}}>{clientName}</span> 
						</div>
					</Col>
					</Row>
					{edit?<div className="cirtificate_edit_services">
						<div className="cirtificate_editeble">
							{serviceData && serviceData.map((item,index)=>{
								return <CheckBox key={index} type="checkbox" name="service" checked={item.show} value={item.param_value} service={item.param_description} onChange={(e)=>serviceCheck(e,item.param_value,item)}/>
							})}
						</div>
					</div>:""}
         </div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td>
          {/* <!--place holder for the fixed-position footer--> */}
          <div className="page-footer-space mt-5">
		  <div className="text-center d-print-none" >
		<span style={{fontWeight:700}}>  {invoice?.client_details.billing_address+', '+invoice?.client_details.billing_city+', '+invoice?.client_details.billing_state+', '+invoice?.client_details.billing_zipcode} {usaFormatNumber(invoice?.client_details.billing_phone)}</span>
						<p>Electronically produced on <span style={{color:'rgb(239 198 73)'}}>WashTRAX<sup>TM</sup></span> and no signature required. All rights reserved. © 2021   </p>
					</div> 
		  </div>
        </td>
      </tr>
    </tfoot>

  </table>
  <style>{`
           @page {
            margin: 20mm
          } 
  `}</style>
		</Card>
    )
}
export default WashoutCertificate